import { writable } from 'svelte/store';
import type { CustomerOrderData } from './types/types';

export const worker = writable(null);
export const market = writable('CZ');
export const queue = writable(null);
export const order = writable<CustomerOrderData | null>(null);
export const printServiceStatus = writable<string>('Odpojená');
export const webSocketUrl = writable<string | null>(null);
export const printServiceUrl = writable<string | null>(null);
export const defaultShippingLabel = writable<string | null>(null);
export const boxRowsOpen = writable<boolean>(true);
