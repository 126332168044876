import type {
	ApiResponse,
	CustomerOrderData,
	EspoResponseList,
	ExpeditionStatus,
	Item,
} from '../types/types';
import { worker, queue, market, order, boxRowsOpen } from '../stores';
import { get } from 'svelte/store';
import type { AjaxPromise } from 'espocrm/src/ajax';
import { push } from 'svelte-spa-router';

export function updateOrder(
	status: ExpeditionStatus,
	internalMessage: string | null = null,
	toggleReturnModal: (message: string) => void,
) {
	const updateData = {
		status: status,
	};
	if (internalMessage !== null) {
		updateData['description'] = internalMessage;
	}

	const salesOrder = get(order);
	if (salesOrder === null) {
		return;
	}

	Espo.Ajax.putRequest('SalesOrder/' + salesOrder.id, updateData);
	//console.log("updating order", updateData);
	const data = {
		warehouseWorkerId: get(worker),
		queue: get(queue),
		market: get(market),
	};

	(
		Espo.Ajax.postRequest('SalesOrder/reserveOrder', data) as AjaxPromise<
			ApiResponse<CustomerOrderData>
		>
	).then((response: ApiResponse<CustomerOrderData>) => {
		if (response.success === true) {
			order.set(null);
			order.set(response.data);
			boxRowsOpen.set(true);
			window.scrollTo(0, 0);
		} else {
			toggleReturnModal(response.message);
			order.set(null);
		}
	}).catch(() => {
		toggleReturnModal("Něco se pokazilo, zkuste obnovit stránku");
		order.set(null);
	});
}

export function isCompleted() {
	let isCompleted = true;
	get(order)
		?.items.filter(item => item.type !== 'KRABICE')
		.forEach(item => {
			if (item.status !== 'completed') {
				isCompleted = false;
			}
		});

	get(order)?.bundles.forEach(item => {
		if (item.status !== 'completed') {
			isCompleted = false;
		}
	});

	const boxCount = get(order)?.boxCount ?? 0;
	if (boxCount < 1) {
		isCompleted = false;
	}
	return isCompleted;
}

export async function addNonExistingItem(
	ean: string,
	count: number,
	toggleFunction: () => boolean,
	toggleAlertModal: (message: string) => void,
) {
	toggleFunction();
	const response = (await Espo.Ajax.getRequest('Product', {
		where: [
			{
				type: 'or',
				value: [
					{
						type: 'equals',
						attribute: 'ean',
						value: ean,
					},
					{
						type: 'equals',
						attribute: 'ean',
						value: '0' + ean,
					},
				],
			},
		],
	})) as EspoResponseList<any>;

	if (response.total > 0) {
		order.update(val => {
			if (val === null) {
				return null;
			}
			//deep copy of order
			const valOrder: CustomerOrderData = JSON.parse(JSON.stringify(val));
			const responseItem = response.list[0];
			const item: Item = {
				productId: responseItem.id,
				name: responseItem.name,
				ean: responseItem.ean,
				type: responseItem.type,
				price: responseItem.price,
				itemOrder: responseItem.order,
				scannedQuantity: count,
				orderQuantity: 0,
				status: 'completed',
				isGift: true,
				image: responseItem.pictureId
					? `/?entryPoint=image&size=large&id=${responseItem.pictureId}`
					: 'client/modules/expedition-portal/lib/expedition-portal/default-image.png',
			};

			if (valOrder !== null) {
				valOrder.items = [...valOrder.items, item];
				order.set(valOrder);
			}
			return valOrder;
		});
	} else {
		toggleAlertModal('produkt s eanem ' + ean + ' neexistuje');
	}
}

export function addGift(
	item: Item,
	Count: number,
	toggleFunction: () => boolean,
) {
	const orderData = get(order);
	if (orderData === null) {
		return;
	}
	orderData.items.forEach(i => {
		if (i.productId === item.productId) {
			if (i.type === 'KRABICE') {
				i.scannedQuantity = i.scannedQuantity + Count;
				orderData.boxCount += Count;
				if (orderData.boxCount >= 1) {
					orderData.boxStatus = 'completed';
				}
			} else {
				//pokud produkt z id jiz exisuj i jako darek tak se zvisi jenom pocet
				if (
					orderData.items.find(
						i =>
							i.productId === item.productId && i.isGift === true,
					) &&
					i.isGift
				) {
					//console.log("finded");
					i.scannedQuantity += Count;
				} else if (
					!orderData.items.find(
						i =>
							i.productId === item.productId && i.isGift === true,
					) &&
					!i.isGift
				) {
					const newItem = { ...i };
					newItem.scannedQuantity = Count;
					newItem.orderQuantity = 0;
					newItem.price = 0;
					newItem.status = 'completed';
					newItem.isGift = true;
					orderData.items = [...orderData.items, newItem];
				}
			}
		}
	});
	order.set(orderData);
	toggleFunction();
}
export function unrelatedWarehouseWorker() {
	const orderData = get(order);
	order.set(null);
	queue.set(null);
	if (orderData?.id != null) {
		Espo.Ajax.putRequest('SalesOrder/' + orderData.id, {
			warehouseWorkerId: null,
		})
			.then(() => {
				push('/queue');
			})
			.catch(() => {
				console.log('order may not exist or some other error');
				push('/queue');
			});
	} else {
		console.log('warehouse worker is not related to order');
	}
}
