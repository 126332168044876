import {
	order,
	webSocketUrl as printerWebSocketUrl,
	defaultShippingLabel,
	printServiceUrl,
	printServiceStatus,
} from '../stores';
import { get } from 'svelte/store';
import type { ApiResponse, PrimaryShippingLabel } from '../types/types';
import type { AjaxPromise } from 'espocrm/src/ajax';
import { WebSocketPrinter } from '../../lib/webSocketPrinter';

export class PrinterService {
	private readonly printWebSocket: WebSocketPrinter;

	/**
	 *
	 * @throws {Error}
	 */
	constructor() {
		this.printWebSocket = this.createWebsocketPrinter();
	}

	/**
	 * @throws {Error}
	 */
	public async printLabel() {
		const orderData = get(order);
		if (orderData === null) {
			console.error('Order data is not set');
			return;
		}
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');
		if (
			orderData.internalName === 'PICKUP' ||
			orderData.realCarrier === 'PICKUP' ||
			!orderData.hasValidCarrierProvider
		) {
			const url = get(printServiceUrl);
			if (!url) {
				console.error('Print service URL is not set');
				throw new Error('Print service URL is not set');
			}

			const defaultShippingLabelId = get(defaultShippingLabel);
			if (!defaultShippingLabelId) {
				console.error('Print service URL is not set');
				throw new Error('Print service URL is not set');
			}
			// This is dependent on the apertiaPrinter service

			await this.printPDFBase64(
				`/?entryPoint=pdf&entityType=SalesOrder&entityId=${orderData.id}&templateId=${defaultShippingLabelId}`,
			);
			return;
		}

		(
			Espo.Ajax.getRequest(
				`SalesOrder/primaryShippingLabel/${orderData.id}`,
			) as AjaxPromise<ApiResponse<PrimaryShippingLabel>>
		)
			.then((response: ApiResponse<PrimaryShippingLabel>) => {
				if (response.success === true) {
					this.sendPrintRequest(response.data.shippingLabelId);
				} else {
					console.error('Failed to get primary shipping label');
				}
			})
			.catch(error => {
				console.error('Failed to get primary shipping label', error);
			});
	}

	/**
	 * @throws {Error}
	 * */
	createWebsocketPrinter(): WebSocketPrinter {
		const webSocketUrl = get(printerWebSocketUrl);
		if (!webSocketUrl) {
			throw new Error('WebSocket URL is not set');
		}
		return new WebSocketPrinter({
			url: webSocketUrl,
			onConnect: function () {
				printServiceStatus.set('Připojená');
				console.log('Connected');
			},
			onDisconnect: function () {
				printServiceStatus.set('Odpojená');
				console.log('Disconnected');
			},
			onUpdate: function (message) {
				printServiceStatus.set(message);
				console.log('Status:', message);
			},
		});
	}

	/**
	 * @throws {Error}
	 * */
	private async fetchAndDisplayPDF(pdfUrl: string) {
		const response = await fetch(pdfUrl);
		const arrayBuffer = await response.arrayBuffer();
		return btoa(
			new Uint8Array(arrayBuffer).reduce(
				(data, byte) => data + String.fromCharCode(byte),
				'',
			),
		);
	}

	/**
	 * @throws {Error}
	 * */
	private async printPDFBase64(pdfUrl: string) {
		const base64 = await this.fetchAndDisplayPDF(pdfUrl);
		this.printWebSocket.submit({
			type: 'LABEL',
			url: 'file.pdf',
			file_content: base64,
		});
	}

	/**
	 * @throws {Error}
	 * */
	private async sendPrintRequest(labelId: string) {
		await this.printPDFBase64('/?entryPoint=download&id=' + labelId);
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');
		const url = get(printServiceUrl);
		if (!url) {
			console.error('Print service URL is not set');
			throw new Error('Print service URL is not set');
		}
		return fetch(url, {
			method: 'POST',
			headers: headers,
			body: JSON.stringify({
				param1: labelId,
			}),
		});
	}
}
